import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { useTrademarkConfigurator } from '../../hooks/useTrademarkConfigurator'
import { useCheckout } from '../../hooks/useCheckout'
import Cart from '../Global/Cart'
import PricingContainer from './PricingContainer'
import fbTrack from '../../context/facebook'

const PricingView = ({ pageData, locale, images, contentfulCheckoutData }) => {
  var configurator = useTrademarkConfigurator({ locale })
  var checkoutState = useCheckout(locale)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const sourceWebsite = urlParams.get('source_website')
    if (sourceWebsite === 'haloo') {
      checkoutState.setSourceWebsite('haloo')
    }
  }, [checkoutState.state.source_website])

  const countryIndex = {
    CA: locale == 'en-CA' ? 0 : 1,
    US: locale == 'en-US' ? 0 : 1,
    'CA,US': 2
  }

  useEffect(() => {
    fbTrack('track', 'ViewContent')
    if (checkoutState.state.current_step === 5) {
      checkoutState.setCurrentStep(0)
    }
  }, [])

  return (
    <Container className='px-0' fluid>
      <PricingContainer
        configurator={configurator}
        pageData={pageData}
        locale={locale}
        images={images}
        data={contentfulCheckoutData}
      />
      <Cart
        pageData={pageData}
        countrySelection={
          countryIndex[configurator.state.country_selection.toString()]
        }
        locale={locale}
        checkoutState={checkoutState}
      />
    </Container>
  )
}

export default PricingView
